<template>
  <div style="background: #fff">
    <headers />
    <div class="head">
      <img src="@/assets/images/communityBj.png" />
      <div class="headTit">社区联盟</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <div class="content">
      <div class="headBox">
        <div class="headItem">联盟成员</div>
      </div>
      <template v-if="list.length">
        <div class="card" v-for="(item, index) in list" :key="index">
          <div class="cardImg">
            <img v-if="item.image" class="hoverTransform" :src="item.image" />
            <div v-else class="cardImgTextBox MyCustomFont">
              <div>{{item.abbr}}</div>
            </div>
          </div>
          <div class="cardR">
            <div class="cardRTit">{{ item.name }}</div>
            <div class="cardRIcon">
              <div
                class="cardRIconImte"
                v-if="item.equityClassifyNames.includes('L1')"
              >
                <img src="@/assets/images/L1.png" />
                L1级授权合作伙伴
              </div>
              <div
                class="cardRIconImte"
                v-if="item.equityClassifyNames.includes('L2')"
              >
                <img src="@/assets/images/L2.png" />
                L2级授权合作伙伴
              </div>
              <div
                class="cardRIconImte"
                v-if="item.equityClassifyNames.includes('L3')"
              >
                <img src="@/assets/images/L3.png" />
                L3级授权合作伙伴
              </div>
              <div
                class="cardRIconImte"
                v-if="item.equityClassifyNames.includes('L4')"
              >
                <img src="@/assets/images/L4.png" />
                L4级授权合作伙伴
              </div>
            </div>
            <div class="cardRAddress">
              <img src="@/assets/images/address.png" />
              所在地：{{ item.address }}
            </div>

            <div class="cardRBottom">
              <div class="cardRAddress" style="margin-top: 0">
                <img src="@/assets/images/linkPeople.png" />
                联系人：{{ item.contacts }}
              </div>
              <div class="cardRAddress" style="margin-top: 0">
                <img src="@/assets/images/tel.png" />
                联系方式：{{ item.phone }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="page" v-if="list.length">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.page"
        :page-size="pages.size"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <None v-else />
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { factoryList } from "@/api/lx";
export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      searchValue: "",
      list: [],
      pages: {
        page: 1,
        total: 0,
        size: 10,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    currentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getList();
    },
    getList() {
      factoryList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    toSolutionDetails() {
      this.$router.push({
        path: "/forum/details",
        query: { id: 123 },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
  }
  .searchBox {
    margin-top: 54px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  padding: 0 260px;
  box-sizing: border-box;
  margin-top: 30px;
  .headBox {
    width: 1400px;
    height: 48px;
    background: #f3f3f3;
    .headItem {
      width: 161px;
      height: 48px;
      background: #ffffff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #00161a;
      text-align: center;
      line-height: 48px;
      box-sizing: border-box;
      border-bottom: 3px solid #00d8c3;
    }
  }
  .card {
    width: 1400px;
    height: 200px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 0 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    .cardImg {
      width: 120px;
      height: 120px;
      overflow: hidden;
      .cardImgTextBox {
        width: 100%;
        height: 100%;
        font-size: 34px;
        background-color: #00d8c3;
        padding: 20px;
        box-sizing: border-box;
        color: #fff;
        word-break: break-all;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
          text-align: center;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cardR {
      margin-left: 20px;
      .cardRTit {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #00161a;
      }
      .cardRIcon {
        display: flex;
        margin-top: 9px;
        .cardRIconImte {
          display: flex;
          align-items: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #525f66;
          margin-right: 15px;
          img {
            width: 44px;
            height: 32px;
            margin-right: 15px;
          }
        }
      }
      .cardRAddress {
        display: flex;
        align-items: center;
        margin-top: 11px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }
      .cardRBottom {
        display: flex;
        margin-top: 10px;
        div {
          margin-right: 39px;
        }
      }
    }
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 65px;
}
</style>